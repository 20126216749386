import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { EventEmitter } from '@angular/core';
import { DropdownOption } from '@limestone/ls-shared-modules';
import { DateTime } from 'luxon';
import { LsTableEditConfig } from './LsTableEditConfig';

export class LsDataGridConfig {
  allowAdding? = false;
  allowDeleting?: boolean = false;
  allowDragColumns?: boolean = false;
  allowDragRows?: boolean = false;
  dragRowsResultEmitter?: EventEmitter<any[]>;
  allowEditing?: boolean = false;
  allowExpansion?: boolean = false;
  allowExporting? = false;
  formAdding?: boolean = false;
  formEditing?: boolean = false;
  allowSingleClickEditing?: boolean = false;
  displayFooter?: boolean = false;
  gridButtons?: GridButton[];
  gridColumns: GridColumn[];
  gridDataDetails?: Map<number | string, { config: LsDataGridConfig; data: any }>; // id of gridData
  gridDataFooter?: Map<number | string, GridFooter>;
  multiSelect?: boolean = false;
  page?: PageEvent;
  pageSizeOptions?: number[] = [];
  showHideColumns?: boolean = false;
  singleSelect?: boolean = false;
  totalDataLength?: number;
  uniqueIdentifier?: string[];
  suppressRowSelect?: boolean = false;
  tableEditConfig?: LsTableEditConfig = null;
}

export class GridColumn {
  label: string;
  value: string;
  columnAlias?: string;
  dataType?:
    | 'text'
    | 'number'
    | 'select'
    | 'date'
    | 'time'
    | 'datetime'
    | 'checkbox'
    | 'autocomplete'
    | 'percent'
    | 'button'
    | 'menu-button'
    | 'more-button';
  dateTimeFormat?: string;
  dateTimeZone?: string;
  enableSort?: boolean;
  enableFilter?: boolean;
  enableEdit?: boolean;
  enableGrouping?: boolean;
  enableCellButton?: boolean;
  currencyCode?: string;
  dropdownOptions?: DropdownOption[];
  validation?: NumberValidation | DateValidation | TextValidation | SelectValidation;
  footerVal?: string | number;
  icon?: string;
  menuButtons?: GridMenuButton[];
  allowContains?: boolean;
  sticky?: boolean;
  stickyEnd?: boolean;
  link?: string;
  size?: 'medium' | 'large' = 'medium';
}

export class GridButton {
  label: string;
  event: string;
}

export class RequiredValidation {
  required?: boolean = true;

  constructor(required?: boolean) {
    this.required = required;
  }
}

export class NumberValidation extends RequiredValidation {
  numberOfDecimals?: number;
  min?: number;
  max?: number;
  currencyCode?: string;

  constructor(required?: boolean, numberOfDecimals?: number, min?: number, max?: number, currencyCode?: string) {
    super(required);
    this.numberOfDecimals = numberOfDecimals;
    this.min = min;
    this.max = max;
    this.currencyCode = currencyCode;
  }
}

export class DateValidation extends RequiredValidation {
  uniqueDate?: boolean;
  minDate?: Date;
  maxDate?: Date;
  constructor(required?: boolean, uniqueDate?: boolean, minDate?: Date, maxDate?: Date) {
    super(required);
    this.uniqueDate = uniqueDate ?? false;
    this.minDate = minDate ?? null;
    this.maxDate = maxDate ?? null;
  }
}

export class DateTimeValidation extends RequiredValidation {
  uniqueDate?: boolean;
  minDate?: DateTime;
  maxDate?: DateTime;
  constructor(required?: boolean, uniqueDate?: boolean, minDate?: DateTime, maxDate?: DateTime) {
    super(required);
    this.uniqueDate = uniqueDate ?? false;
    this.minDate = minDate ?? null;
    this.maxDate = maxDate ?? null;
  }
}

export class TextValidation extends RequiredValidation {
  maxLength?: number;
  invalidChars?: string; // Regex format.
  emailValidator?: string;

  constructor(required?: boolean, maxLength?: number, invalidChars?: string, isEmail?: boolean) {
    super(required);
    this.maxLength = maxLength;
    this.invalidChars = invalidChars;
    if (isEmail) {
      this.emailValidator = '^(\\w|\\d|-|%|!|#|\\$|!|#|&|\\*|\\+|\\^|~|\\.)+@(\\w|\\d|-|\\.)+(\\.(\\w|\\d){2,15})$';
    }
  }
}

export class SelectValidation extends RequiredValidation {
  constructor(required?: boolean) {
    super(required);
  }
}

export class GridFooter {
  label: string;
  value: string;
}

export class GridMenuButton {
  text: string;
  icon: string;
  isDisabled: (data?: any) => boolean;
  handler: (data?: any) => any;
}
